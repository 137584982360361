import React, {useEffect, useRef, useState} from 'react';
import {Box, Card, Grid, Typography} from '@mui/material';
import debounce from 'lodash.debounce';
import axios from "axios";
import {Link, useLocation, useParams} from "react-router-dom";
import {useAuthHeader} from "react-auth-kit";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {csCZ} from '@mui/x-date-pickers/locales';
import 'dayjs/locale/cs';
import dayjs from 'dayjs';
import BudgetTable from "../components/BudgetTable";
import CashflowIncomes from "../components/CashflowIncomes";
import CashflowOutcomes from "../components/CashflowOutcomes";
import Contracts from "../components/Contracts";
import Phases from "../components/Phases";
import BankTable from "./BankTable";

const cardTitles = [
  'Cashflow',
  'Rozpočet',
  'Zakázky',
  'F0',
  'F1',
  'F2',
  'F3',
  'F4',
  'F5',
  'F6',
  'F7',
];

//TODO: Move to CashflowOutcomes
const phases = [
  {
    "code": "F0",
    "name": "F0 - Nákup"
  },
  {
    "code": "F1",
    "name": "F1 - Projekční práce"
  },
  {
    "code": "F2",
    "name": "F2 - Stavba"
  },
  {
    "code": "F3",
    "name": "F3 - Projektové řízení"
  },
  {
    "code": "F4",
    "name": "F4 - Marketing a prodej"
  },
  {
    "code": "F5",
    "name": "F5 - Pozáruční servis"
  },
  {
    "code": "F6",
    "name": "F6 - Firma"
  },
  {
    "code": "F7",
    "name": "F7 - Finanční náklady"
  },
  {
    "code": "Total",
    "name": "Celkem"
  }
];

//TODO: Move to CashflowIncomes
//Sale and rent removed based on client request
const incomeTypes = [
  {
    "code": "INVESTICE",
    "name": "Investice"
  },
  {
    "code": "PŮJČKA - BANKA",
    "name": "Půjčka - banka"
  },
  {
    "code": "PŮJČKA - SKUPINA",
    "name": "Půjčka - skupina"
  },
  {
    "code": "DPH",
    "name": "DPH"
  },
  {
    "code": "Total",
    "name": "Celkem"
  },
  {
    "code": "dphImpact",
    "name": "Vliv DPH"
  },
  {
    "code": "cashflow",
    "name": "Čistý finanční tok"
  },
]

const EntityDetail = ({entity, entityName, apiPath, navPath, dateFrom, dateTo}) => {
  const firstTableRef = useRef(null);
  const secondTableRef = useRef(null);
  const thirdTableRef = useRef(null);
  const [isRowsRendered, setIsRowsRendered] = useState(false);
  const [selectedCard, setSelectedCard] = useState('Cashflow');
  const [tableData, setTableData] = useState([]);
  const [entityData, setEntityData] = useState({});
  const [businessMonthFrom, setBusinessMonthFrom] = useState(dateFrom || "0124");
  const [businessMonthTo, setBusinessMonthTo] = useState(dateTo || "1224");
  const location = useLocation();
  const {name} = location.state || {};
  const {id} = useParams();
  const getAuthHeader = useAuthHeader();

  const updateTimeInterval = async (from, to) => {
    try {
      const token = getAuthHeader();
      await axios.post(`https://flexioverview.upi-group.cz/${apiPath}/update`, {
        costCenterCode: id, timeInterval: {
          from: from, to: to
        }, volumeStudyValueList: null, designBudgetValueList: null
      }, {headers: {Authorization: token}});
    } catch (error) {
      console.error('Error updating time interval:', error);
    }
  }
  const mapCompanyDataToTables = (data) => {
    if (!data || !data.contractList) {
      return {};
    }

    const tableData = [];
    const businessMonthSums = {};

    data.contractList
      .filter(contract => (contract.phaseList.includes(selectedCard) || contract.sourceList.includes(selectedCard)))
      .forEach((contract) => {
        const userValue = contract.userValueList.find(userValue => userValue.label === selectedCard);
        if (!userValue) {
          return;
        }

        const businessMonthList = userValue.businessMonthScopeList
          .map(item => item.businessMonth)
          .filter(month => userValue.businessMonthAssumptionList.map(item => item.businessMonth).includes(month));

        businessMonthList.forEach((businessMonth) => {
          const scope = userValue.businessMonthScopeList.find(item => item.businessMonth === businessMonth).value;
          const assumption = userValue.businessMonthAssumptionList.find(item => item.businessMonth === businessMonth).value;

          let invoices = data.receivedInvoiceList.filter(
            item => (item.phaseList.includes(selectedCard) || item.sourceList.includes(selectedCard)) &&
              item.contract === contract.code
          );

          if (!businessMonthSums[businessMonth]) {
            businessMonthSums[businessMonth] = {
              assumptionValue: 0,
              scopeValue: 0,
              difference: 0,
              scope: 0,
              sumScope: 0,
              costAssumption: 0,
              sumCostAssumption: 0
            };
          }

          const contractScope = parseFloat(contract.scope) || 0;
          const contractCostAssumption = parseFloat(contract.costAssumption) || 0;

          businessMonthSums[businessMonth].assumptionValue += assumption;
          businessMonthSums[businessMonth].scopeValue += scope;
          businessMonthSums[businessMonth].difference += Math.abs(assumption - scope);
          businessMonthSums[businessMonth].scope += contractScope;
          businessMonthSums[businessMonth].sumScope += userValue.businessMonthScopeList.reduce((sum, item) => sum + item.value, 0);
          businessMonthSums[businessMonth].costAssumption += contractCostAssumption;
          businessMonthSums[businessMonth].sumCostAssumption += userValue.businessMonthAssumptionList.reduce((sum, item) => sum + item.value, 0);

          if (invoices.length === 0) {
            tableData.push({
              hierarchy: [formatDate(businessMonth), contract.code],
              id: `${contract.code}-${businessMonth}`,
              contractNumber: contract.code,
              contractName: contract.description,
              contractNumberLabel: `${contract.code} - ${formatDate(businessMonth)}`,
              businessMonth: businessMonth,
              assumptionValue: assumption,
              scopeValue: scope,
              difference: Math.abs(assumption - scope),
              isEditable: true,
              scope: contractScope,
              sumScope: userValue.businessMonthScopeList.reduce((sum, item) => sum + item.value, 0),
              costAssumption: contractCostAssumption,
              sumCostAssumption: userValue.businessMonthAssumptionList.reduce((sum, item) => sum + item.value, 0)
            });
          } else {
            invoices.forEach((invoice, index) => {
              invoice.paymentList.forEach(payment => {
                const paymentMonth = convertDateToMMYY(payment.paymentDate);

                if (paymentMonth === businessMonth) {
                  tableData.push({
                    hierarchy: invoices.length === 1 ? [formatDate(businessMonth), contract.code] : [formatDate(businessMonth), contract.code, invoice.code],
                    id: `${contract.code}-${businessMonth}-${invoice.code}-${payment.paymentDate}`,
                    contractNumber: contract.code,
                    contractNumberLabel: `${contract.code} - ${formatDate(businessMonth)}`,
                    businessMonth: businessMonth,
                    isEditable: invoices.length === 1,
                    company: invoice.company,
                    description: invoice.description,
                    amountBase: invoice.amountBase,
                    amountVat: invoice.amountVat,
                    amountTotal: invoice.amountTotal,
                    actualPayment: payment.paymentAmount,
                    dueDate: invoice.dueDate,
                    expoDate: invoice.expoDate,
                    paymentDate: payment.paymentDate,
                    statusPaid: getStatus(invoice.statusPaid),
                    scope: contractScope,
                    sumScope: userValue.businessMonthScopeList.reduce((sum, item) => sum + item.value, 0),
                    costAssumption: contractCostAssumption,
                    sumCostAssumption: userValue.businessMonthAssumptionList.reduce((sum, item) => sum + item.value, 0)
                  });
                }
              });

              if (invoices.length > 1 && index === 0) {
                tableData.push({
                  hierarchy: [formatDate(businessMonth), contract.code],
                  id: `${contract.code}-${businessMonth}`,
                  contractNumber: contract.code,
                  contractName: contract.description,
                  isEditable: true,
                  businessMonth: businessMonth,
                  assumptionValue: assumption,
                  scopeValue: scope,
                  costAssumption: contractCostAssumption,
                  sumCostAssumption: userValue.businessMonthAssumptionList.reduce((sum, item) => sum + item.value, 0),
                  scope: contractScope,
                  sumScope: userValue.businessMonthScopeList.reduce((sum, item) => sum + item.value, 0),
                  difference: Math.abs(assumption - scope),
                });
              }
            });
          }
        });
      });

    Object.keys(businessMonthSums).forEach(businessMonth => {
      const sums = businessMonthSums[businessMonth];

      tableData.push({
        hierarchy: [formatDate(businessMonth)],
        id: businessMonth,
        contractNumber: "",
        contractNumberLabel: "",
        businessMonth: businessMonth,
        assumptionValue: sums.assumptionValue,
        scopeValue: sums.scopeValue,
        difference: sums.difference,
        scope: sums.scope,
        sumScope: sums.sumScope,
        costAssumption: sums.costAssumption,
        sumCostAssumption: sums.sumCostAssumption
      });
    });

    // Sort tableData by businessMonth in MMYY format
    tableData.sort((a, b) => {
      const getDateFromMMYY = (mmyy) => {
        const month = parseInt(mmyy.substring(0, 2), 10) - 1; // JS months are 0-indexed
        const year = parseInt('20' + mmyy.substring(2, 4), 10); // assuming all dates are in the 2000s
        return new Date(year, month);
      };

      const dateA = getDateFromMMYY(a.businessMonth);
      const dateB = getDateFromMMYY(b.businessMonth);

      return dateA - dateB;
    });

    setTableData(prevTableData => ({
      ...prevTableData, [selectedCard]: tableData
    }));
  };


  const convertDateToMMYY = (dateString) => {
    const date = new Date(dateString.split('+')[0]);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear().toString().slice(-2);

    const formattedMonth = month.toString().padStart(2, '0');
    return `${formattedMonth}${year}`;
  };

  function convertDateToMMYYDayjs(date) {
    return dayjs(date).format('MMYY').toString();
  }

  function formatDate(input) {
    if (input.length !== 4) {
      return input;
    }
    return `${input.slice(0, 2)}/${input.slice(2)}`;
  }

  function getStatus(status) {
    switch (status) {
      case 'not-paid':
        return 'Neuhrazeno';
      case 'partially-paid':
        return 'Částečně uhrazeno';
      case 'fully-paid':
        return 'Uhrazeno';
      case '':
        return '';
      default:
        return 'Neznámý status';
    }
  }

  function convertStringToDate(dateString) {
    if (!dateString || dateString === '') {
      return
    }
    if (dateString.length !== 4) {
      return
    }
    const month = parseInt(dateString.substring(0, 2), 10);
    const year = parseInt(`20${dateString.substring(2, 4)}`, 10);

    return dayjs(new Date(year, month - 1, 1));
  }

  function generateMonthArray(from, to) {
    if (!from || !to) {
      return [];
    }
    const fromMonth = parseInt(from.substring(0, 2), 10);
    const fromYear = parseInt(`20${from.substring(2, 4)}`, 10);
    const toMonth = parseInt(to.substring(0, 2), 10);
    const toYear = parseInt(`20${to.substring(2, 4)}`, 10);

    let startDate = dayjs(new Date(fromYear, fromMonth - 1, 1));
    const endDate = dayjs(new Date(toYear, toMonth - 1, 1));

    const months = [];

    while (startDate.isBefore(endDate) || startDate.isSame(endDate, 'month')) {
      const month = startDate.format('MMYY');
      months.push(month);
      startDate = startDate.add(1, 'month');
    }

    return months;
  }

  useEffect(() => {
    const firstTable = firstTableRef.current?.querySelector('.MuiDataGrid-virtualScroller');
    const secondTable = secondTableRef.current?.querySelector('.MuiDataGrid-virtualScroller');
    const thirdTable = thirdTableRef.current?.querySelector('.MuiDataGrid-virtualScroller');

    if (!firstTable && !secondTable && !thirdTable) return;

    const syncScroll = (source, targets) => {
      targets.forEach(target => {
        if (target) {
          target.scrollLeft = source.scrollLeft;
          target.scrollTop = source.scrollTop;
        }
      });
    };

    const createScrollHandler = (sourceTable, targetTables) => {
      if (!sourceTable) return null;
      return debounce(() => {
        syncScroll(sourceTable, targetTables);
      }, 60);
    };

    const handleFirstTableScroll = createScrollHandler(firstTable, [secondTable, thirdTable]);
    const handleSecondTableScroll = createScrollHandler(secondTable, [firstTable, thirdTable]);
    const handleThirdTableScroll = createScrollHandler(thirdTable, [firstTable, secondTable]);

    const addScrollListeners = (table, handler) => {
      if (table && handler) table.addEventListener('scroll', handler);
    };

    const removeScrollListeners = (table, handler) => {
      if (table && handler) table.removeEventListener('scroll', handler);
    };

    addScrollListeners(firstTable, handleFirstTableScroll);
    addScrollListeners(secondTable, handleSecondTableScroll);
    addScrollListeners(thirdTable, handleThirdTableScroll);

    return () => {
      removeScrollListeners(firstTable, handleFirstTableScroll);
      removeScrollListeners(secondTable, handleSecondTableScroll);
      removeScrollListeners(thirdTable, handleThirdTableScroll);
    };
  }, [selectedCard, isRowsRendered]);

  useEffect(() => {
    if (entity) setEntityData(entity.data)
  }, [entity]);

  useEffect(() => {
    if (!entityData || !entityData.report) {
      return;
    }
    mapCompanyDataToTables(entityData.report);
    if (selectedCard !== "cashflow") {
      setIsRowsRendered(false);
    }
  }, [selectedCard, entityData]);

  useEffect(() => {
    if (!entityData || !entityData.report) {
      return;
    }
    mapCompanyDataToTables(entityData.report);
  }, [entityData]);

  const handleDateFromChange = (newValue) => {
    setBusinessMonthFrom(convertDateToMMYYDayjs(newValue.toString()));
    updateTimeInterval(convertDateToMMYYDayjs(newValue.toString()), businessMonthTo);
  };
  const handleDateToChange = (newValue) => {
    setBusinessMonthTo(convertDateToMMYYDayjs(newValue.toString()));
    updateTimeInterval(businessMonthFrom, convertDateToMMYYDayjs(newValue.toString()));
  };

  const handleRowsChange = () => {
    setIsRowsRendered(true);
  };

  const handleLabelClick = (phase) => {
    setSelectedCard(phase.split(" ")[0]);
  }


  return (
    <Box>
      <div className="container"
           style={{position: 'relative', width: "100%", justifyContent: "center", alignItems: "center"}}>
        <Typography variant="h5" fontWeight={600}>
          Detail {entityName.toLowerCase()} "{(entity && (entity?.data?.report?.costCenterDetail?.name)) ?? name}"
        </Typography>
        <Typography variant="h7" fontWeight={400}><Link to="/home" style={{
          textDecoration: 'none',
          color: 'black'
        }}>NOHO</Link> -> <Link to={navPath} style={{textDecoration: 'none', color: 'black'}}>{entityName}</Link> ->
          Detail </Typography>
        <Box sx={{width: "100%", marginTop: "10px", backgroundColor: '#f5f5f5'}}>
          <Grid container sx={{overflowX: 'auto', padding: 1, marginBottom: 1}} justifyContent="start">
            {cardTitles.map((title) => (<Grid item lg={1} md={2} xs={4} key={title} sx={{height: '100%'}}>
              <Card onClick={() => setSelectedCard(title)} elevation={0}
                    sx={{
                      width: '100px',
                      borderRadius: '4px',
                      height: '40px',
                      display: 'flex',
                      flexDirection: 'column',
                      cursor: 'pointer',
                      backgroundColor: selectedCard === title ? '#EAEAEA' : '#f5f5f5',
                      color: selectedCard === title ? '#003366' : 'black'
                    }}>
                <Typography variant="h7" sx={{
                  fontSize: '0.75rem',
                  textTransform: 'uppercase',
                  display: 'flex',
                  width: 'auto',
                  height: '45px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  fontWeight: 600
                }}>
                  {title}
                </Typography>
              </Card>
            </Grid>))}
            {selectedCard === "Cashflow" && phases && phases?.length !== 0 && (
              <div style={{paddingTop: "15px"}}>
                <LocalizationProvider adapterLocale="cs" dateAdapter={AdapterDayjs}
                                      localeText={csCZ.components.MuiLocalizationProvider.defaultProps.localeText}>
                  <DatePicker
                    label="Od"
                    views={['year', 'month']}
                    value={convertStringToDate(businessMonthFrom)}
                    onChange={handleDateFromChange}
                    sx={{width: "200px", marginRight: "15px", backgroundColor: "white", borderRadius: "4px"}}
                  />
                  <DatePicker
                    label="Do"
                    views={['year', 'month']}
                    value={convertStringToDate(businessMonthTo)}
                    onChange={handleDateToChange}
                    sx={{marginRight: "15px", backgroundColor: "white", borderRadius: "4px"}}
                  />
                </LocalizationProvider>
              </div>
            )}
          </Grid>
        </Box>
        <Box>
          {
            selectedCard === "Cashflow" && entityData && entityData?.report && phases?.length > 0 ? (
              <>
                <Box sx={{marginTop: '20px'}}>
                  <CashflowOutcomes
                    data={entityData}
                    months={generateMonthArray(businessMonthFrom, businessMonthTo)}
                    rowDefinitions={phases}
                    reference={firstTableRef}
                    onRowsChange={handleRowsChange}
                    handleLabelClick={handleLabelClick}
                  />
                </Box>
                <Box sx={{marginTop: '20px'}}>
                  <CashflowIncomes
                    data={entityData}
                    months={generateMonthArray(businessMonthFrom, businessMonthTo)}
                    rowDefinitions={incomeTypes}
                    reference={secondTableRef}
                    onRowsChange={handleRowsChange}
                  />
                </Box>
                {entityData?.report?.bankList && (
                  <Box sx={{marginTop: '20px'}}>
                    <BankTable
                      data={entityData}
                      months={generateMonthArray(businessMonthFrom, businessMonthTo)}
                      rowDefinitions={[{code: "vydej", name: "Výdaje"}, {code: "prijem", name: "Příjmy"}]}
                      reference={thirdTableRef}
                      onRowsChange={handleRowsChange}
                    />
                  </Box>
                )}
              </>
            ) : selectedCard === "Rozpočet" && phases?.length > 0 ? (
              entityData.report && <BudgetTable  data={entityData} title="Náklady dle fáze"/>
            ) : selectedCard === "Zakázky" && phases?.length > 0 ? (
              <Contracts data={entityData}/>
            ) : (
              <Phases data={tableData[selectedCard]} isLoading={false} companyData={entityData}
                      selectedCard={selectedCard} setCompanyData={setEntityData}
                      selectedTableData={tableData[selectedCard]} company={entity}/>
            )
          }
        </Box>
      </div>
    </Box>);
};

export default EntityDetail;
